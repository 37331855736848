import React from "react";
import cardPackageStyle from "./package.module.css";
import { MdCall } from "react-icons/md";
import { Badge, SHAPE, COLOR } from "baseui/badge";
import {
  DuLocationGreen,
  DuLocationRed,
} from "../../../../utils/ImportAssets/ImportSvg";
const PackageDetails = ({ partnerBookData }) => {
  return (
    <div className={cardPackageStyle.packageDetailsContainer}>
      <section>
        <span>Package Details</span>
        {partnerBookData.ACTION === "RESERVE" ? null : (
          <Badge
            content={partnerBookData.status}
            shape={SHAPE.pill}
            color={COLOR.positive}
          />
        )}
      </section>
      <div className={cardPackageStyle.mainPartnerDetails}>
        <div className={cardPackageStyle.customerDetails}>
          <DuLocationGreen style={{ minWidth: "5%" }} />
          <span>{partnerBookData.bottom_text}</span>
        </div>
        <>
          {partnerBookData.drop_address && (
            <div
              className={cardPackageStyle.customerDetails}
              style={{ marginTop: "0.5rem" }}
            >
              <DuLocationRed style={{ minWidth: "5%" }} />
              <span>{partnerBookData.drop_address}</span>
            </div>
          )}
        </>
        <div className={cardPackageStyle.activeCardDetailsMain}>
          <div className={cardPackageStyle.cardLayoutDU}>
            {!partnerBookData.customer_number === true ? null : (
              <>
                <div>Customer</div>
                <a
                  href={`tel:${partnerBookData.customer_number}`}
                  className={cardPackageStyle.customerCall}
                >
                  <span>{partnerBookData.customer}</span>
                  <MdCall
                    className="bg-green-600 text-white rounded-full p-1"
                    size={24}
                  />
                </a>
              </>
            )}
          </div>
          {partnerBookData.details.map((elem, index) => {
            return (
              <div className={cardPackageStyle.cardLayoutDU} key={index}>
                <div>{elem.key}</div>
                <section>{elem.value}</section>
              </div>
            );
          })}
        </div>
        <div className={cardPackageStyle.packageWrapperDate}>
          {partnerBookData.ACTION === "RESERVE" ? (
            <>
              {partnerBookData.booking_days.map((date, idx) => {
                date = date.date.split(" ");
                return (
                  <div className={cardPackageStyle.packageBoxDate} key={idx}>
                    <div className={cardPackageStyle.dateBookBox}>
                      <div>{date[0]}</div>
                      <span>{`${date[1]} ${date[2]}`}</span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {partnerBookData.booking_days.upcoming_bookings.map(
                (date, idx) => {
                  date = date.date.split(" ");
                  return (
                    <div className={cardPackageStyle.packageBoxDate} key={idx}>
                      <div className={cardPackageStyle.dateBookBox}>
                        <div>{date[0]}</div>
                        <span>{`${date[1]}`}</span>
                      </div>
                    </div>
                  );
                }
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
