import React, { useContext, useEffect } from "react";
import { Banner, HIERARCHY, KIND } from "baseui/banner";
import { Spinner } from "baseui/spinner";
import ReserveCard from "../../components/Mobile/DuDailyPartner/ReservationBlock/ReserveCard/ReserveCard";
import ActiveDuCard from "../../components/Mobile/DuDailyPartner/ReservationBlock/ActiveDuCard/ActiveDuCard";
import useGetReserveBooking from "../../hooks/useGetReserveBooking";
import useGetActiveBooking from "../../hooks/useGetActiveBooking";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { dailyPageOpenPartner } from "../../utils/EventObject";
import cleverTapPartner from "../../cleverTapConfig";

const AllTypesBooking = () => {
  // const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { driverData } = useContext(ApplicationContext);
  const url = `${process.env.REACT_APP_WEBSITE_URL}/web/du-daily/packages/?driver_id=${driverData.driver_id}&imei_number=${driverData.imei_number}`;
  const activeURL = `${process.env.REACT_APP_WEBSITE_URL}/web/du-daily/active-packages/?driver_id=${driverData.driver_id}&imei_number=${driverData.imei_number}`;
  const { reserveBookData, isReserveLoading, error } =
    useGetReserveBooking(url);
  const { activeBookData, isActiveLoading, activeError } =
    useGetActiveBooking(activeURL);
  useEffect(() => {
    if (reserveBookData.length === 0 || activeBookData.length === 0) {
    } else {
      dailyPageOpenPartner.page = "packages_list";
      dailyPageOpenPartner.active_packages = activeBookData.length;
      dailyPageOpenPartner.available_packages = reserveBookData.length;
      cleverTapPartner.event.push("Daily page opened", dailyPageOpenPartner);
    }
  }, [reserveBookData, activeBookData]);
  return (
    <div className="mt-8 ml-4 mb-8 w-full h-auto flex flex-col items-start gap-4 overflow-y-auto">
      <div className="flex flex-col items-start gap-4 w-96">
        <strong>Active DriveU Daily Package</strong>
        <div className="w-full mx-auto flex items-start gap-4 overflow-x-auto pr-4">
          {activeError ? (
            <Banner hierarchy={HIERARCHY.low} kind={KIND.negative}>
              <h2>Error: Something went wrong.</h2>
              <p>Please try again later.</p>
            </Banner>
          ) : (
            <>
              {isActiveLoading ? (
                <Spinner $color="green" />
              ) : (
                <>
                  {activeBookData.length === 0 ? (
                    <Banner hierarchy={HIERARCHY.low} kind={KIND.negative}>
                      {activeError ||
                        "No Active Booking. Please reserve a package."}
                    </Banner>
                  ) : (
                    <>
                      {activeBookData.map((data, index) => {
                        return <ActiveDuCard key={index} activeData={data} />;
                      })}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className="flex flex-col items-start gap-4 w-96">
        <strong>Check other Packages to reserve</strong>
        {/* <ReserveCard /> */}
        <div className="w-full mx-auto flex items-start gap-4 overflow-x-auto pr-4">
          {error ? (
            <Banner hierarchy={HIERARCHY.low} kind={KIND.negative}>
              <h2>Error: Something went wrong.</h2>
              <p>Please try again later.</p>
            </Banner>
          ) : (
            <>
              {isReserveLoading ? (
                <Spinner $color="green" />
              ) : (
                <>
                  {reserveBookData.length === 0 ? (
                    <Banner hierarchy={HIERARCHY.low} kind={KIND.negative}>
                      DU Daily Packages Not Available
                    </Banner>
                  ) : (
                    <>
                      {reserveBookData.map((data, index) => {
                        return <ReserveCard key={index} reserveData={data} />;
                      })}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllTypesBooking;
