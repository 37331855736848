import React, { Fragment, useContext, useEffect } from "react";
import { quizContent } from "../../utils/QuizContent";
import { Button, SIZE } from "baseui/button";
import dailyStyles from "../../components/Mobile/DuDailyPartner/PackageDetails/package.module.css";
import { useNavigate } from "react-router-dom";
import cleverTapPartner from "../../cleverTapConfig";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { toast } from "react-toastify";
import {
  dailyQuizHomePageOpen,
  dailyQuizStarted,
  quizLanguagePopUpOpen,
  quizLanguageConfirmed,
} from "../../utils/EventObject";

const QuizLandingPage = () => {
  const navigate = useNavigate();
  const [isOpenLangModal, setIsOpenLangModal] = React.useState(false);
  const { quizLang, setQuizLang, quizzes, driverData } =
    useContext(ApplicationContext);
  const [languages, setLanguages] = React.useState([]);

  useEffect(() => {
    if (quizzes.length !== 0) {
      const quizLang = Object.keys(quizzes.languages);
      setLanguages(quizLang);
      dailyQuizHomePageOpen.city = driverData.city_name;
      quizLanguagePopUpOpen.city = driverData.city_name;
      quizLanguageConfirmed.city = driverData.city_name;
      cleverTapPartner.event.push(
        "Quiz Homepage Opened",
        dailyQuizHomePageOpen
      );
    }
    // eslint-disable-next-line
  }, [quizzes]);

  const handleSelectQuizLanguage = () => {
    if (!quizLang) {
      toast.error("Please Select Quiz Language");
    } else {
      setIsOpenLangModal(false);
      navigate("/du-daily-partner-quiz-start");
      dailyQuizStarted.city = driverData.city_name;
      cleverTapPartner.event.push("Quiz Started", dailyQuizStarted);
    }
  };
  const handleRedirectToQuiz = () => {
    if (!quizLang) {
      setIsOpenLangModal(true);
      cleverTapPartner.event.push(
        "Quiz Language Popup Opened",
        quizLanguagePopUpOpen
      );
    } else {
      navigate("/du-daily-partner-quiz-start");
      dailyQuizStarted.city = driverData.city_name;
      cleverTapPartner.event.push("Quiz Started", dailyQuizStarted);
    }
  };

  const handleChooseQuizLanguage = (e) => {
    const langVal = e.target.value;
    setQuizLang(langVal);
    quizLanguageConfirmed["Language"] = langVal;
    cleverTapPartner.event.push(
      "Quiz Language Confirmed",
      quizLanguageConfirmed
    );
  };

  return (
    <div className="flex flex-col justify-between w-full h-[68%] mt-4">
      <div className="w-full h-auto">
        <div className="w-11/12 mx-auto text-base font-extrabold leading-5 tracking-tight text-left">
          Check your understanding of DU Daily
        </div>
        <div className="w-11/12 mx-auto mt-4 flex flex-col items-center">
          {quizContent.map((elem) => {
            return (
              <Fragment key={elem.id}>
                <div className="box-border flex flex-row items-center justify-start w-full gap-6">
                  <div className="box-border flex-0">
                    <img
                      src={elem.image}
                      alt={elem.content}
                      className="box-border block w-30 h-30 object-contain"
                      width={30}
                      height={30}
                    />
                  </div>
                  <p className="box-border flex-0 flex-grow ml-6 text-black text-base border-b border-black border-opacity-25 py-2 w-full">
                    {elem.content}
                  </p>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="w-11/12 mx-auto mt-4 flex items-center justify-between">
        <div>Select Quiz Language</div>
        <Button
          onClick={() => {
            setIsOpenLangModal(true);
            cleverTapPartner.event.push(
              "Quiz Language Popup Opened",
              quizLanguagePopUpOpen
            );
          }}
          size={SIZE.compact}
        >
          Quiz Language
        </Button>

        <Modal
          onClose={() => {
            setIsOpenLangModal(false);
            setQuizLang("");
          }}
          isOpen={isOpenLangModal}
        >
          <ModalHeader>Select Quiz Language</ModalHeader>
          <ModalBody>
            <div className="flex flex-col items-start justify-center">
              {languages.length !== 0 && (
                <>
                  {languages.map((choice, idx) => (
                    <label
                      key={idx}
                      className="inline-flex items-center mt-3 bg-[#FCFDF7] w-full py-1"
                    >
                      <input
                        type="radio"
                        name="quiz-choice"
                        className="form-radio h-5 w-5 text-green-600"
                        value={choice}
                        onChange={handleChooseQuizLanguage}
                        checked={quizLang === choice}
                      />
                      <span className="ml-2 text-gray-700">{choice}</span>
                    </label>
                  ))}
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <ModalButton
              kind="tertiary"
              onClick={() => {
                setIsOpenLangModal(false);
                setQuizLang("");
              }}
            >
              Cancel
            </ModalButton>
            <ModalButton onClick={handleSelectQuizLanguage}>Okay</ModalButton>
          </ModalFooter>
        </Modal>
      </div>
      <div className="w-11/12 mx-auto mb-2 mt-4 h-auto"></div>
      <div
        className={dailyStyles["reservation-container"]}
        onClick={handleRedirectToQuiz}
      >
        <div className={dailyStyles["reservation-button-container"]}>
          <Button className={dailyStyles["reserve-button"]}>Start Quiz</Button>
        </div>
      </div>
    </div>
  );
};

export default QuizLandingPage;
