import {
  Calendar,
  DailyTick,
  LanguageDU,
} from "../../../../../utils/ImportAssets/ImportPng.js";

export const featuresObject = [
  {
    id: 1,
    content: "Guaranteed earnings for up to a week from same customer",
    image: DailyTick,
  },
  {
    id: 2,
    content: "Same pickup location and pickup time for all dates",
    image: Calendar,
  },
  {
    id: 3,
    content: "No language issue",
    image: LanguageDU,
  },
];
