// Import necessary hooks and components from react-router-dom, React itself, and context
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";

// Import the global context and CSS for styling
import { ApplicationContext } from "./context/ApplicationContextProvider";
import "./App.css";

// Import components for displaying toast notifications and its CSS
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // useLocation hook to access the current URL's path and search parameters
  const { pathname, search } = useLocation();
  // useNavigate hook for programmatically changing routes
  const navigate = useNavigate();
  // Parsing URL search parameters
  const params = new URLSearchParams(search);
  // useContext hook to access the ApplicationContext's value, particularly the setDriverData function
  const { setDriverData } = useContext(ApplicationContext);

  // useEffect hook to perform side-effects based on pathname changes
  useEffect(() => {
    // Scrolls to the top of the window on every navigation
    window.scrollTo(0, 0);

    // Checks if the current pathname matches a specific route
    if (pathname === "/du-daily-partner/") {
      // Constructs an object from URL search parameters
      const duDailyPartnerParamsObject = {
        lat: params.get("latitude"),
        lng: params.get("longitude"),
        driver_id: params.get("driver_id"),
        imei_number: params.get("imei_number"),
        name: params.get("name"),
        mobile: params.get("mobile"),
        email: params.get("email"),
        city_name: params.get("city_name"),
        driver_zone: params.get("driver_zone"),
        show_quiz: params.get("show_quiz"),
      };

      // Updates the global state with the new driver data
      setDriverData((prevParams) => ({
        ...prevParams, // Keep the previous state
        ...duDailyPartnerParamsObject, // Update with new parameters
      }));
      // Navigates to the same path, ensuring search parameters are retained
      navigate(`/du-daily-partner/${search}`);
    }
    // Dependency array includes pathname to re-run the effect when pathname changes
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      {/* Main content area where routed components will be rendered */}
      <main className="w-full" id="App">
        <Outlet />
      </main>
    </>
  );
}

export default App;
