import React from "react";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
const MapBoxBlock = ({ latitude, longitude }) => {
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  React.useEffect(() => {
    // Initialize Mapbox GL
    mapboxgl.accessToken = MAPBOX_TOKEN;
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v12",
      center: [longitude, latitude],
      zoom: 14,
    });
    new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full h-72 relative">
      <div id="map" className="absolute top-0 bottom-0 w-full h-full" />
    </div>
  );
};

export default MapBoxBlock;
