import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "baseui/spinner";
import { Button, SIZE } from "baseui/button";
import {
  QuizRightIcon,
  QuizWrongIcon,
} from "../../utils/ImportAssets/ImportSvg";
import { useNavigate } from "react-router-dom";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import {
  quizQuestionNumber,
  quizResponse,
  quizResultPageCTAClicked,
  quizResultPageOpened,
  quizSubmitted,
} from "../../utils/EventObject";
import cleverTapPartner from "../../cleverTapConfig";
import instance from "../../axiosBase";
import { toast } from "react-toastify";

const QuizStartPage = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const { quizLang, quizzes, isQuizLoading, driverData } =
    useContext(ApplicationContext);
  const navigate = useNavigate();
  const [filterQuizQues, setFilterQuizQues] = useState(null);

  const handleChoice = (choice) => {
    setUserAnswers({
      ...userAnswers,
      [currentQuestionIndex]: choice,
    });
    quizResponse.city = driverData.city_name;
    quizResponse.Response = choice;
    quizResponse.Question = filterQuizQues[currentQuestionIndex].question;
    cleverTapPartner.event.push("Quiz Response Chosen", quizResponse);
  };

  const handleNext = () => {
    setCurrentQuestionIndex((current) => current + 1);
    quizQuestionNumber["action button used"] = "next";
    cleverTapPartner.event.push("Quiz Question Opened", quizQuestionNumber);
  };

  const handlePrev = () => {
    setCurrentQuestionIndex((current) => current - 1);
    quizQuestionNumber["action button used"] = "prev";
    cleverTapPartner.event.push("Quiz Question Opened", quizQuestionNumber);
  };

  const handleSubmit = () => {
    const quizAnsObj = [];
    filterQuizQues.forEach((elem, index) =>
      quizAnsObj.push({ question: elem.question, answer: userAnswers[index] })
    );

    const submitQuizObj = {
      driver_id: driverData.driver_id,
      imei_number: driverData.imei_number,
      answers: quizAnsObj,
    };

    const submitQuizResponse = instance.post(
      `${process.env.REACT_WEBSITE_URL}/web/du-daily/quiz/`,
      submitQuizObj
    );
    submitQuizResponse
      .then(({ data }) => {
        if (data.status === "success") {
          toast.success("Quiz Submitted Successfully");
        } else {
          console.log(data.message);
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(err.message);
      });
    setIsSubmitted(true);
    countMatchingAnswers(filterQuizQues, userAnswers);

    quizSubmitted.city = driverData.city_name;
    cleverTapPartner.event.push("Quiz Submitted", quizSubmitted);
  };

  const countMatchingAnswers = (array, answerObj) => {
    let count = 0;

    array.forEach((item, index) => {
      if (item.answer === answerObj[index]) {
        count++;
      }
    });
    setCorrectAnswer(count);

    quizResultPageOpened["CTA loaded"] =
      count >= quizzes.minimum_score ? "Continue" : "Retake Quiz";
    quizResultPageOpened.correct = count;
    quizResultPageOpened.incorrect = filterQuizQues.length - count;
    quizResultPageOpened.result =
      count >= quizzes.minimum_score ? "Pass" : "Fail";
    quizResultPageOpened.city = driverData.city_name;
    cleverTapPartner.event.push(
      "Quiz Result page opened",
      quizResultPageOpened
    );
  };
  const handleRedirectToPage = () => {
    if (correctAnswer >= quizzes.minimum_score) {
      navigate("/du-daily-partner-all-bookings");
    } else {
      navigate("/du-daily-partner-quiz");
    }

    quizResultPageCTAClicked["CTA loaded"] =
      correctAnswer >= quizzes.minimum_score ? "Continue" : "Retake Quiz";

    quizResultPageCTAClicked.result =
      correctAnswer >= quizzes.minimum_score ? "Pass" : "Fail";
    quizResultPageCTAClicked.city = driverData.city_name;
    cleverTapPartner.event.push(
      "Quiz Result page CTA clicked",
      quizResultPageCTAClicked
    );
  };

  useEffect(() => {
    if (quizzes.length !== 0) {
      const langData = quizzes.languages;
      const filterQuizByLang = Object.entries(langData);
      const setQuizData = filterQuizByLang.filter(
        (lang) => lang[0] === quizLang
      );
      // Check if filtered data is available and set it to state
      if (setQuizData.length > 0) {
        // Assuming setQuizData[0][1] is the array of questions for the selected language
        setFilterQuizQues(setQuizData[0][1]);
      }
    }
    // Adding quizLang to dependency array to re-run the effect when it changes
  }, [quizzes, quizLang]);

  useEffect(() => {
    if (quizzes.length !== 0) {
      const langData = quizzes.languages;
      const filterQuizByLang = Object.entries(langData);
      const setQuizData = filterQuizByLang.filter(
        (lang) => lang[0] === quizLang
      );
      // Check if filtered data is available and set it to state
      if (setQuizData.length > 0) {
        // Assuming setQuizData[0][1] is the array of questions for the selected language
        quizQuestionNumber.city = driverData.city_name;
        quizQuestionNumber["Question Sequence"] =
          setQuizData[0][1][currentQuestionIndex].id;
      }
    }
    // Adding quizLang to dependency array to re-run the effect when it changes
    // eslint-disable-next-line
  }, [quizzes, quizLang, currentQuestionIndex]);

  return (
    <div className="w-full mt-4">
      <div className="w-11/12 m-auto">
        {isQuizLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner $size="50px" $borderWidth="5px" $color="green" />
          </div>
        ) : (
          <>
            {filterQuizQues && !isSubmitted && (
              <div>
                <div className="mx-auto text-base font-medium leading-5 tracking-tight text-left">
                  Question {currentQuestionIndex + 1}/{filterQuizQues.length}
                </div>
                <div className="text-xl font-extrabold tracking-normal text-left mt-5 leading-6">
                  {filterQuizQues[currentQuestionIndex].question}
                </div>
                <div className="flex flex-col items-start justify-center">
                  {filterQuizQues[currentQuestionIndex].choices.map(
                    (choice) => (
                      <label
                        key={choice}
                        className="inline-flex items-start mt-3 bg-[#FCFDF7] w-full py-2"
                      >
                        <div>
                          <input
                            type="radio"
                            name="quiz-choice"
                            className="form-radio h-[15px] w-[15px] text-green-600"
                            value={choice}
                            onChange={() => handleChoice(choice)}
                            checked={
                              userAnswers[currentQuestionIndex] === choice
                            }
                          />
                        </div>
                        <span className="ml-2 text-gray-700">{choice}</span>
                      </label>
                    )
                  )}
                </div>

                <div className="mt-10 flex items-center w-full justify-between">
                  <div>
                    {currentQuestionIndex > 0 && (
                      <Button onClick={handlePrev} size={SIZE.compact}>
                        Previous
                      </Button>
                    )}
                  </div>
                  <div
                    className={
                      currentQuestionIndex < filterQuizQues.length - 1
                        ? ""
                        : "submitBtn"
                    }
                  >
                    {currentQuestionIndex < filterQuizQues.length - 1 ? (
                      <Button
                        onClick={handleNext}
                        size={SIZE.compact}
                        disabled={!userAnswers[currentQuestionIndex]}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        disabled={!userAnswers[currentQuestionIndex]}
                        className="w-full"
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="w-full">
        {isSubmitted && (
          <div>
            <div className="overflow-y-scroll w-11/12 m-auto h-[40rem]">
              <div className="mx-auto text-base font-bold leading-5 tracking-tight text-left">
                Check your understanding of DU Daily
              </div>
              <div className="border border-black rounded-2xl p-4 mt-5">
                <div className="flex items-center gap-4">
                  <span className="font-bold">
                    {correctAnswer >= quizzes.minimum_score ? "Pass" : "Fail"}
                  </span>
                  <span>
                    {correctAnswer >= quizzes.minimum_score ? (
                      <QuizRightIcon />
                    ) : (
                      <QuizWrongIcon className="rounded-full" />
                    )}
                  </span>
                </div>
                <div className="mt-3 font-bold">
                  Score: {`${correctAnswer} / ${filterQuizQues.length}`}
                </div>
                <hr className="border-t-2 border-gray-400 my-2" />
                <div className="mt-3 font-bold flex items-center justify-between">
                  <span>
                    Correct: {`${correctAnswer} / ${filterQuizQues.length}`}
                  </span>
                  <span>
                    Wrong:{" "}
                    {`${filterQuizQues.length - correctAnswer} / ${
                      filterQuizQues.length
                    }`}
                  </span>
                </div>
              </div>

              <div className="mt-5">
                {filterQuizQues.map((question, index) => (
                  <div key={question.id} className="mb-4">
                    <div className="font-bold">Question: {question.id}</div>
                    <div className="text-xl font-extrabold">
                      {question.question}
                    </div>

                    <div>
                      {question.choices.map((choice, idx) => {
                        let choiceStyle = "p-2 my-1 flex items-center ";
                        let answerSymbol = "○"; // Default symbol for incorrect answer
                        let answerLabel = "";

                        // If user's score meets the minimum score
                        if (correctAnswer >= quizzes.minimum_score) {
                          if (choice === question.answer) {
                            choiceStyle += "bg-green-200";
                            answerSymbol = "◉"; // Symbol for correct answer
                            answerLabel = " (Correct Answer)";
                          } else if (choice === userAnswers[index]) {
                            choiceStyle += "bg-red-200";
                            answerLabel = " (Your Answer)";
                          } else {
                            choiceStyle += "bg-[#FCFDF7]";
                          }
                        } else {
                          // If user's score doesn't meet the minimum score
                          if (choice === userAnswers[index]) {
                            choiceStyle +=
                              choice === question.answer
                                ? "bg-green-200"
                                : "bg-red-200";
                            answerSymbol =
                              choice === question.answer ? "◉" : "○";
                            answerLabel =
                              choice === question.answer
                                ? " (Correct)"
                                : " (Incorrect)";
                          } else {
                            choiceStyle += "bg-[#FCFDF7]";
                          }
                        }
                        return (
                          <div key={idx}>
                            <div key={choice} className={choiceStyle}>
                              <span className={`text-[#127e40]`}>
                                {answerSymbol}&nbsp;
                              </span>
                              {choice}
                              {answerLabel}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              className="w-full sticky bottom-0 h-[100px] flex flex-col items-center justify-start pt-5 px-4 bg-white rounded-t-md"
            >
              <Button className="w-full" onClick={handleRedirectToPage}>
                {correctAnswer >= quizzes.minimum_score
                  ? "Continue"
                  : "Retake Quiz"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizStartPage;
