import React, { useContext, useState } from "react";
import { Button } from "baseui/button";
import { useNavigate } from "react-router-dom";
import MapBoxBlock from "../../components/Mobile/DuDailyPartner/MapBoxBlock/MapBoxBlock";
import PackageDetails from "../../components/Mobile/DuDailyPartner/PackageDetails/PackageDetails";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import instance from "../../axiosBase";
import { toast } from "react-toastify";

const StatusPackage = () => {
  const { partnerBookData, driverData } = useContext(ApplicationContext);
  const [actionText, setActionText] = useState(null);
  const navigate = useNavigate();
  const [actionLoading, setActionLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_WEBSITE_URL;

  const handleReservePost = (data, actionText) => {
    setActionLoading(true);
    if (data.ACTION === "RESERVE" && actionText === null) {
      const postData = {
        driver_id: driverData.driver_id,
        imei_number: driverData.imei_number,
        booking_id: data.booking_id,
        latitude: data.package_latitude,
        longitude: data.package_longitude,
      };
      const ReservePOSTData = instance.post(
        `${BASE_URL}/web/du-daily/reserve-package/`,
        postData
      );
      ReservePOSTData.then(({ data }) => {
        if (data.status === "success") {
          setActionText(data);
          setActionLoading(false);
        } else {
          console.log(data.message);
          toast.error(data.message);
          setActionLoading(false);
        }
      }).catch((err) => {
        console.log(err.message);
        toast.error(err.message);
        setActionLoading(false);
      });
    } else if (data.ACTION === "TAKE" || actionText.action === "TAKE") {
      const postData = {
        driver_id: driverData.driver_id,
        imei_number: driverData.imei_number,
        booking_id: data.upcoming_booking_id,
        latitude: data.package_latitude,
        longitude: data.package_longitude,
      };
      const AssignedPOSTData = instance.post(
        `${BASE_URL}/web/du-daily/assign-package/`,
        postData
      );
      AssignedPOSTData.then(({ data }) => {
        if (data.status === "success") {
          navigate("/du-daily-partner-assigned");
          setActionLoading(false);
        } else {
          console.log(data.message);
          setActionLoading(false);
          toast.error(data.message);
        }
      }).catch((err) => {
        console.log(err.message);
        setActionLoading(false);
        toast.error(err.message);
      });
    }
  };

  return (
    <div>
      <MapBoxBlock
        latitude={partnerBookData.package_latitude}
        longitude={partnerBookData.package_longitude}
      />
      <div className="p-4 mb-20 overflow-y-scroll max-h-[calc(100vh-80px)]">
        <PackageDetails partnerBookData={partnerBookData} />
      </div>
      <div className="text-center p-2 fixed bottom-0 w-full">
        {actionText === null ? (
          <>
            {partnerBookData.ACTION === "RESERVE" ||
            partnerBookData.ACTION === "TAKE" ? (
              <>
                {actionLoading ? (
                  <Button className="w-full" isLoading>
                    {partnerBookData.button_text}
                  </Button>
                ) : (
                  <Button
                    className="w-full"
                    onClick={() =>
                      handleReservePost(partnerBookData, actionText)
                    }
                  >
                    {partnerBookData.button_text}
                  </Button>
                )}
              </>
            ) : (
              <Button className="w-full" disabled>
                {partnerBookData.button_text}
              </Button>
            )}
          </>
        ) : (
          <>
            {actionText.action === "" ? (
              <Button className="w-full" disabled>
                {actionText.button_text}
              </Button>
            ) : (
              <>
                {actionLoading ? (
                  <Button className="w-full" isLoading>
                    {actionText.button_text}
                  </Button>
                ) : (
                  <Button
                    className="w-full"
                    onClick={() =>
                      handleReservePost(partnerBookData, actionText)
                    }
                  >
                    {actionText.button_text}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default StatusPackage;
