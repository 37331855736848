import { useEffect, useState } from "react";
import instance from "../axiosBase";

const useGetReserveBooking = (url) => {
  const [reserveBookData, setReserveBookData] = useState([]);
  const [isReserveLoading, setIsReserveLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsReserveLoading(true);
    instance
      .get(url)
      .then(({ data }) => {
        if (data.status === "success") {
          setReserveBookData(data.data);
        } else {
          setError("No Reserve Booking Found");
        }
        setIsReserveLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsReserveLoading(false);
      });
    // eslint-disable-next-line
  }, []);
  return { reserveBookData, isReserveLoading, error };
};

export default useGetReserveBooking;
