import React, { Fragment, useContext, useEffect } from "react";
import { featuresObject } from "./content";
import { Button } from "baseui/button";
import { useNavigate } from "react-router-dom";
import dailyStyles from "../../PackageDetails/package.module.css";
import { ApplicationContext } from "../../../../../context/ApplicationContextProvider";
import instance from "../../../../../axiosBase";

const DuPartnerContent = () => {
  const navigate = useNavigate();
  const { setQuizzes, setIsQuizLoading, setQuizError, driverData } =
    useContext(ApplicationContext);
  const handleRedirectToBookingCard = () => {
    if (driverData.show_quiz === "true" || driverData.show_quiz === true) {
      navigate("/du-daily-partner-quiz");
    } else {
      navigate("/du-daily-partner-all-bookings");
    }
  };
  useEffect(() => {
    
    const url = `${process.env.REACT_APP_WEBSITE_URL}/web/du-daily/quiz/`;
    
    const quizParams = {
      driver_id: driverData.driver_id,
      imei_number: driverData.imei_number,
    };
    if (driverData.driver_id && driverData.imei_number) {
      instance
        .get(url, { params: quizParams })
        .then(({ data }) => {
          if (data.status === "success") {
            
            setQuizzes(data);
          } else {
            setQuizError("No Active Booking Found");
          }
          setIsQuizLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setQuizError(err.message);
          setIsQuizLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [driverData]);
  return (
    <div className="flex flex-col justify-between w-full h-[68%] mt-4">
      <div className="w-full h-auto">
        <div className="w-11/12 mx-auto mt-4 flex flex-col items-center">
          {featuresObject.map((elem) => {
            return (
              <Fragment key={elem.id}>
                <div className="box-border flex flex-row items-center justify-start w-full gap-6">
                  <div className="box-border flex-0">
                    <img
                      src={elem.image}
                      alt={elem.content}
                      className="box-border block w-30 h-30 object-contain"
                      width={30}
                      height={30}
                    />
                  </div>
                  <p className="box-border flex-0 flex-grow ml-6 text-black text-base border-b border-black border-opacity-25 py-2 w-full">
                    {elem.content}
                  </p>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="w-11/12 mx-auto mb-2 mt-4 h-auto"></div>
      <div
        className={dailyStyles["reservation-container"]}
        onClick={handleRedirectToBookingCard}
      >
        <div className={dailyStyles["reservation-button-container"]}>
          <Button className={dailyStyles["reserve-button"]}>
            Check Weekly Packages
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DuPartnerContent;
