import { useEffect, useState } from "react";
import instance from "../axiosBase";

const useGetActiveBooking = (url) => {
  const [activeBookData, setActiveBookData] = useState([]);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [activeError, setActiveError] = useState("");

  useEffect(() => {
    setIsActiveLoading(true);
    instance
      .get(url)
      .then(({ data }) => {
        if (data.status === "success") {
          setActiveBookData(data.data);
        } else {
          setActiveError("No Active Booking Found");
        }
        setIsActiveLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsActiveLoading(false);
      });
    // eslint-disable-next-line
  }, []);
  return { activeBookData, isActiveLoading, activeError };
};

export default useGetActiveBooking;
