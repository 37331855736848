import React, { useContext } from "react";
import TrackPackagePartner from "../../components/Mobile/DuDailyPartner/PackageDetails/TrackPackagePartner";
import MapBoxBlock from "../../components/Mobile/DuDailyPartner/MapBoxBlock/MapBoxBlock";
import { ApplicationContext } from "../../context/ApplicationContextProvider";

const AssignedPartnerPage = () => {
  const { partnerBookData } = useContext(ApplicationContext);
  return (
    <div className="h-auto mb-4 overflow-y-scroll">
      <MapBoxBlock
        latitude={partnerBookData.package_latitude}
        longitude={partnerBookData.package_longitude}
      />
      <div className="mb-4 h-auto">
        <TrackPackagePartner partnerBookData={partnerBookData} />
      </div>
    </div>
  );
};

export default AssignedPartnerPage;
