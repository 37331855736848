import {
  DuTickQuiz,
  InfinityDuQuiz,
  LanguageDU,
} from "../utils/ImportAssets/ImportPng.js";

export const quizContent = [
  {
    id: 1,
    content: "Unlimited Attempts",
    image: InfinityDuQuiz,
  },
  {
    id: 2,
    content: "Quiz in your preferred language",
    image: LanguageDU,
  },
  {
    id: 3,
    content: "You must have an overall score of 80% to pass",
    image: DuTickQuiz,
  },
];
