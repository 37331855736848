export const dailyPageOpenPartner = {
  "Service Name": "du_daily",
  page: "",
  active_packages: "",
  available_packages: "",
  driver_zone: "",
  city_name: "",
};
export const packageDetailPagePartner = {
  "Service Name": "du_daily",
  order_id: "",
  "package status": "",
  "cta shown": "",
  "widget source": "",
  "Estimated usage": "",
  "pickup time": "",
  upcoming: "",
  completed: "",
  cancelled: "",
  "start date": "",
  earnings: "",
};
export const dailyQuizHomePageOpen = {
  "Service Name": "du_daily",
  city: "",
};
export const dailyQuizStarted = {
  "Service Name": "du_daily",
  city: "",
};
export const quizQuestionNumber = {
  "Service Name": "du_daily",
  "Question Sequence": "",
  city: "",
  "action button used": "",
};
export const quizResponse = {
  Response: "",
  Question: "",
  city: "",
  "Service Name": "du_daily",
};
export const quizSubmitted = {
  city: "",
  "Service Name": "du_daily",
};
export const quizResultPageOpened = {
  "CTA loaded": "",
  correct: "",
  incorrect: "",
  result: "",
  city: "",
  "Service Name": "du_daily",
};
export const quizResultPageCTAClicked = {
  "CTA clicked": "",
  result: "",
  city: "",
  "Service Name": "du_daily",
};
export const quizLanguagePopUpOpen = {
  city: "",
  "Service Name": "du_daily",
};
export const quizLanguageConfirmed = {
  city: "",
  "Service Name": "du_daily",
  Language: "",
};
