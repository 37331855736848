import React from "react";
import ImageScrollPartner from "../../components/Mobile/DuDailyPartner/DuDailyLandingPage/ImageScrollPartner.js";
import DuPartnerContent from "../../components/Mobile/DuDailyPartner/DuDailyLandingPage/DuPartnerContent/DuPartnerContent.js";
import { ApplicationContext } from "../../context/ApplicationContextProvider.js";
import cleverTapPartner from "../../cleverTapConfig.js";
import { dailyPageOpenPartner } from "../../utils/EventObject.js";
const DuDailyPartnerLandingPage = () => {
  const { driverData } = React.useContext(ApplicationContext);
  React.useEffect(() => {
    if (!driverData.driver_id === true) {
    } else {
      cleverTapPartner.profile.push({
        Site: {
          Name: driverData.name,
          Identity: driverData.mobile, // String or number
          Email: driverData.email, // Email address of the user
          Phone: `+91${driverData.mobile}`, // Phone (with the country code)
        },
      });
      dailyPageOpenPartner.page = "home";
      dailyPageOpenPartner.driver_zone = driverData.driver_zone;
      dailyPageOpenPartner.city_name = driverData.city_name;
      cleverTapPartner.event.push("Daily page opened", dailyPageOpenPartner);
    }
  }, [driverData]);
  return (
    <div className="h-screen w-[100%]">
      <ImageScrollPartner />
      <DuPartnerContent />
    </div>
  );
};

export default DuDailyPartnerLandingPage;
