import { useEffect, useState } from "react";
import instance from "../axiosBase";

const useGetDuPartnerFare = (fareDataDU) => {

  const [fareDUPartner, setFareDUPartner] = useState([]);
  const [isFareLoading, setIsFareLoading] = useState(false);
  const [fareError, setFareError] = useState("");
  const BASE_URL = process.env.REACT_APP_WEBSITE_URL;


  const packagesURL = `${BASE_URL}/web/du-daily/packages/${fareDataDU.booking_id}/?driver_id=${fareDataDU.driver_id}&imei_number=${fareDataDU.imei_number}`


  useEffect(() => {
    setIsFareLoading(true);
    instance
      .get(packagesURL)
      .then(({ data }) => {
        if (data.status === "success") {
          setFareDUPartner(data);
        } else {
          setFareError(data.message);
        }
        setIsFareLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsFareLoading(false);
        setFareError(err.message);
      });
    // eslint-disable-next-line
  }, []);
  return { fareDUPartner, isFareLoading, fareError };
};

export default useGetDuPartnerFare;
